import { useCallback, useEffect, useState } from 'react';

export const useDetectSystemTheme = (): 'dark' | 'light' => {
  const getOsDarkTheme = useCallback(() => window.matchMedia('(prefers-color-scheme: dark)').matches, []);
  const [isOsDarkTheme, setIsOsDarkTheme] = useState(getOsDarkTheme());

  const mediaQueryEventListener = useCallback((event: MediaQueryListEvent) => {
    setIsOsDarkTheme(event.matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', mediaQueryEventListener);

    return () => mediaQuery.removeEventListener('change', mediaQueryEventListener);
  }, [mediaQueryEventListener]);

  return isOsDarkTheme ? 'dark' : 'light';
};
