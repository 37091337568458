import type { PaletteMode } from '@mui/material';
import { createContext, useContext } from 'react';

export interface ConfigContextProps {
  name: PaletteMode | 'system';
  setTheme: (theme: PaletteMode | 'system') => void;
}

export const ConfigContext = createContext<ConfigContextProps>({
  name: 'dark',
  setTheme: () => undefined,
});

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('`useConfig` hook must be used within a `ConfigContext` component');
  }

  return context;
};
