import { Box, CircularProgress, Grid2, Typography } from '@mui/material';
import type { FC } from 'react';
import { muiTheme } from 'shared/theme/theme';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
  text?: boolean;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 'medium', text }) => {
  let circSize;
  switch (size) {
    case 'small':
      circSize = 18;
      break;
    case 'large':
      circSize = 104;
      break;
    default:
      circSize = 56;
      break;
  }

  const spinner = (
    <Box sx={{ position: 'relative', width: 'max-content' }}>
      <CircularProgress
        variant="determinate"
        sx={theme => ({
          color: 'var(--DARK_INTERACTIVE_SECONDARY_PRESSED)',
          ...theme.applyStyles('light', {
            color: 'var(--LIGHT_INTERACTIVE_SECONDARY_PRESSED)',
          }),
        })}
        size={circSize}
        thickness={3.5}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={theme => ({
          color: 'var(--DARK_INTERACTIVE_ACTIVE)',
          position: 'absolute',
          left: 0,
          ...theme.applyStyles('light', {
            color: 'var(--LIGHT_INTERACTIVE_ACTIVE)',
          }),
        })}
        size={circSize}
        thickness={3.5}
      />
    </Box>
  );

  return text ? (
    <Grid2
      container
      sx={[
        {
          gap: muiTheme.spacing(1),
          alignItems: 'center',
          justifyContent: 'center',
        },
        size === 'small'
          ? {
              flexDirection: 'row',
            }
          : {
              flexDirection: 'column',
            },
      ]}
    >
      {spinner}

      {text && <Typography variant={'body2'}>Loading</Typography>}
    </Grid2>
  ) : (
    spinner
  );
};
