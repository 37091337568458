import { createTheme, type Theme } from '@mui/material';

/**
 * This definition is meaningless at runtime... just a reference to the `createTheme` function. This
 * only exists as a means of short-tripping the type resolution of the function and its provided
 * arguments, because this function is hands-down the biggest impact on our type resolution times
 * otherwise.
 *
 * On Aejay's machine, the calls to this function take about 5 seconds to resolve each time the IDE
 * tries to update the type info.
 */
export const createThemeProxy = createTheme as (...options: object[]) => Theme;
