import { type PaletteMode, ThemeProvider } from '@mui/material';
import { type FC, type RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { muiDarkTheme } from '../../theme/darkTheme';
import { muiLightTheme } from '../../theme/lightTheme';
import { ConfigContext } from './ConfigContext';
import { oldLightTheme } from './oldLightTheme';
import { oldDarkTheme } from './oldDarkTheme';
// eslint-disable-next-line barrel-files/avoid-namespace-import
import * as colors from 'shared/theme/colors';
import { useDetectSystemTheme } from 'shared/theme/useDetectSystemTheme';
import { createThemeProxy } from 'shared/theme/createThemeProxy';

export interface ConfigProviderProps {
  children?: React.ReactNode;
  reference?: RefObject<any> | HTMLElement;
}

const isRefObject = (ref: unknown) =>
  ref !== null && typeof ref === 'object' && Object.prototype.hasOwnProperty.call(ref, 'current');

const cssVarsMuiDarkTheme = createThemeProxy({ cssVariables: true, ...muiDarkTheme });
const cssVarsMuiLightTheme = createThemeProxy({ cssVariables: true, ...muiLightTheme });

export const ConfigProvider: FC<ConfigProviderProps> = ({ children, reference = document.body }) => {
  const systemTheme = useDetectSystemTheme();
  const [initialUserTheme, setInitialUserTheme] = useLocalStorage<PaletteMode | 'system'>('@query/theme', 'dark');

  // We have to proxy this through state because calling setInitialUserTheme to change
  // the theme will not result in a re-render (because local storage changes don't emit
  // an event to react to).
  const [userTheme, setUserTheme] = useState<PaletteMode | 'system'>(initialUserTheme ?? 'dark');
  const setTheme = useCallback(
    (newTheme: PaletteMode | 'system') => {
      setUserTheme(newTheme);
      setInitialUserTheme(newTheme);
    },
    [setInitialUserTheme]
  );

  const activeTheme = useMemo(() => {
    if (userTheme === 'system') {
      return systemTheme === 'dark' ? cssVarsMuiDarkTheme : cssVarsMuiLightTheme;
    } else {
      return userTheme === 'dark' ? cssVarsMuiDarkTheme : cssVarsMuiLightTheme;
    }
  }, [userTheme, systemTheme]);

  // This effect sets the CSS variables for the colors defined in the colors.ts file. Some of the colors are set
  // up as arrays, so in those cases we will iterate through the array and append the index to the end of the name.
  useEffect(() => {
    const element = isRefObject(reference) ? (reference as RefObject<any>).current : reference;

    for (const [name, color] of Object.entries(colors)) {
      if (typeof color === 'string') {
        element.style.setProperty(`--${name}`, color);
      } else if (Array.isArray(color) && !color.some(c => typeof c !== 'string')) {
        for (let i = 0; i < color.length; i++) {
          element.style.setProperty(`--${name}_${i}`, color[i]);
        }
      }
    }
  }, [reference]);

  // This is a legacy effect that only exists to set the correct CSS variables based on light/dark theme for the
  // old theme that is not being used by any of our MUI components.  Currently the only page still using this
  // theme is the Welcome page.
  useEffect(() => {
    let oldThemeVars;
    if (userTheme === 'system') {
      oldThemeVars = systemTheme === 'dark' ? oldDarkTheme : oldLightTheme;
    } else {
      oldThemeVars = userTheme === 'dark' ? oldDarkTheme : oldLightTheme;
    }
    const element = isRefObject(reference) ? (reference as RefObject<any>).current : reference;

    for (const prop in oldThemeVars.oldVariables) {
      element.style.setProperty(prop, oldThemeVars.oldVariables[prop]);
    }

    for (const prop in oldThemeVars.oldColors) {
      for (const k in oldThemeVars.oldColors[prop]) {
        element.style.setProperty(`--color-${prop}-${k}`, oldThemeVars.oldColors[prop][k]);
      }
    }
  }, [reference, systemTheme, userTheme]);

  const values = useMemo(
    () => ({
      name: userTheme,
      setTheme,
    }),
    [userTheme, setTheme]
  );

  return (
    <ConfigContext.Provider value={values}>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </ConfigContext.Provider>
  );
};
